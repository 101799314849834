import { DownloadOutlined, EyeOutlined, SearchOutlined } from '@ant-design/icons';
import { show, hide } from '@ebay/nice-modal-react';
import {
    Card,
    TableProps,
    Table,
    Tag,
    Space,
    Button,
    Typography,
    Form,
    FormProps,
    Empty,
    Tooltip,
    Input,
    Row,
    Col,
    Radio,
} from 'antd';
import { ColumnProps } from 'antd/lib/table';
import dayjs, { Dayjs } from 'dayjs';
import { Fragment, useEffect, VFC } from 'react';
import { Link, useLocation, useParams } from 'react-router-dom';

import ApiResult from '../../components/ApiResult';
import DatePicker from '../../components/DatePicker';
import PenaltyDetailsDrawer from '../../components/drawers/PenaltyDetailsDrawer';
import PenaltyStatusTagSelect from '../../components/forms/PenaltyStatusTagSelect';
import PenaltyTypeTagSelect from '../../components/forms/PenaltyTypeTagSelect';
import ProvidersField from '../../components/forms/ProvidersField';
import { ArrowRight, CalendarThree, Close } from '../../components/icons';
import Seo from '../../components/Seo';
import TitleSkeleton from '../../components/TitleSkeleton';
import { useAuth } from '../../context/AuthContext';
import { downloadFile, formatOrderDelivery, getPenaltyStatusColor, sortOrderConverter } from '../../helpers';
import useQueryParams from '../../hooks/queryParams';
import { formatDate, formatNumber, formatPrice, translatePenaltyListStatus, translatePenaltyType } from '../../i18n';
import { PenaltyListPayload } from '../../queries/api/penalties';
import { Penalty, PenaltyStatus, Organization, PenaltyContestationStatus } from '../../queries/api/types';
import { usePenaltyList } from '../../queries/penalties';
import { getRoute, RoutePathName } from '../../routes';

const unfinishedStatuses = Object.values(PenaltyStatus).filter(
    (status) =>
        ![
            PenaltyStatus.closedAfterMeeting,
            PenaltyStatus.closedReimbursed,
            PenaltyStatus.accepted,
            PenaltyStatus.contestationAccepted,
            PenaltyStatus.notContested,
        ].includes(status)
);
type StatusFilter = 'all' | 'waitingForProvider' | 'unfinished';
const getStatusPayload = ({ statusFilter, status }: { statusFilter?: StatusFilter; status?: PenaltyStatus[] }) => {
    const payload = {} as Pick<PenaltyListPayload, 'status' | 'contestationStatus'>;

    switch (statusFilter) {
        case 'all':
            payload.status = status?.length ? status : Object.values(PenaltyStatus);
            break;
        case 'unfinished':
            payload.status = unfinishedStatuses;
            break;
        case 'waitingForProvider':
            payload.contestationStatus = [PenaltyContestationStatus.waitingForProvider];
    }

    return payload;
};
const detailsDrawerId = 'penaltyDetailsDrawer';
let searchTimeout: number;

interface FiltersFormValue {
    status?: PenaltyStatus[];
    type?: string[];
    date?: [Dayjs | null, Dayjs | null];
    organization?: Array<Organization['id']>;
    statusFilter?: StatusFilter;
}

const PenaltiesList: VFC = () => {
    const location = useLocation();
    const [form] = Form.useForm();
    const { penaltyId } = useParams<{ penaltyId?: string }>();
    const isDetails = !!penaltyId;
    const [queryParams, setQueryParams] = useQueryParams('users-list');
    const { user } = useAuth();
    const page = queryParams.get('page') !== null ? parseInt(queryParams.get('page')!, 10) || 0 : 0;
    const sort = queryParams.get('sort') ?? undefined;
    const sortOrder = queryParams.get('sortOrder') ?? undefined;
    const search = queryParams.get('search') ?? undefined;
    const statusFilter = (queryParams.get('statusFilter') as StatusFilter | null) ?? 'unfinished';
    const date = queryParams.getAll('date');
    const status = queryParams.getAll('status') as PenaltyStatus[];
    const type = queryParams.getAll('type');
    const organization = queryParams.getAll('organization');
    const hasFilters = !!status.length || !!type.length || !!organization.length || !!date.length;
    const {
        data: listData,
        isLoading,
        isFetching,
        isError,
        error,
    } = usePenaltyList({
        page,
        ...getStatusPayload({ statusFilter, status }),
        type,
        organization,
        sort,
        sortOrder,
        search,
        ...(date
            ? {
                  fromDate: date[0],
                  toDate: date[1],
              }
            : {}),
    });
    const hasMultiOrg = (user?.scope?.organizations?.length ?? 0) > 1;
    const onClickDownload = (penalty: Penalty) => {
        downloadFile(penalty.proforma?.url);
    };
    const columns: Array<ColumnProps<Penalty>> = (
        [
            {
                key: 'reference',
                title: 'Numéro',
                render: (_, record) => {
                    const hasUnreadMessages =
                        record.computedProperties && !record.computedProperties?.allCommentsReadByUser;
                    const link = (
                        <Link
                            to={{
                                pathname: getRoute(RoutePathName.penalties, { penaltyId: record.id }),
                                search: location.search,
                            }}
                            className="font-16 font-medium"
                            replace
                        >
                            {record.reference}
                        </Link>
                    );

                    return hasUnreadMessages ? (
                        <div className="relative">
                            <Tooltip title="Contient des messages non lus">
                                <span className="unread-dot" aria-hidden>
                                    &nbsp;&nbsp;
                                </span>
                            </Tooltip>
                            {link}
                        </div>
                    ) : (
                        link
                    );
                },
                width: 122,
                sorter: true,
            },
            {
                key: 'date',
                title: "Date d'émission",
                render: (_, record) => formatDate(record.date),
                width: 178,
                sorter: true,
            },
            {
                key: 'status',
                title: 'Statut',
                render: (_, record) => {
                    return <Tag color={getPenaltyStatusColor(record.status)}>{translatePenaltyListStatus(record)}</Tag>;
                },
                ellipsis: true,
                width: 250,
            },
            hasMultiOrg && {
                key: 'organizationName',
                title: 'Fournisseur',
                render: (_, record) => <Tag color="blue">{record.organization.name}</Tag>,
                ellipsis: true,
                width: 250,
                sorter: true,
            },
            {
                key: 'typeName',
                title: 'Type',
                ellipsis: true,
                render: (_, record) => translatePenaltyType(record),
                width: 300,
                sorter: true,
            },
            {
                key: 'orderReference',
                title: 'N° Cde',
                render: (_, record) => {
                    const orders = record.orders || [];

                    return orders.length > 1 ? (
                        <>
                            {formatOrderDelivery(record.orders?.[0])}{' '}
                            <Tooltip
                                title={orders.map((order) => (
                                    <Fragment key={order.reference}>
                                        {formatOrderDelivery(order)}
                                        {order.deliveryDate ? ` - ${formatDate(order.deliveryDate)}` : null}
                                        <br />
                                    </Fragment>
                                ))}
                            >
                                <Tag>+ {orders.length - 1}</Tag>
                            </Tooltip>
                        </>
                    ) : (
                        formatOrderDelivery(record.orders?.[0])
                    );
                },
                width: 128,
                sorter: true,
            },
            {
                key: 'invoice.reference',
                title: 'Facture',
                ellipsis: true,
                render: (_, record) => record.invoice?.reference ?? '—',
                width: 250,
                sorter: true,
            },
            {
                key: 'amount',
                title: 'Total TTC',
                render: (_, record) => formatPrice(record.amount),
                width: 130,
                sorter: true,
            },
            {
                key: 'actions',
                title: 'Actions',
                fixed: 'right',
                width: 144,
                render: (_, record) => (
                    <Space size="middle">
                        <Link
                            to={{
                                pathname: getRoute(RoutePathName.penalties, { penaltyId: record.id }),
                                search: location.search,
                            }}
                        >
                            <Button type="primary" shape="circle" icon={<EyeOutlined />} ghost />
                        </Link>
                        <Button
                            type="primary"
                            shape="circle"
                            icon={<DownloadOutlined />}
                            onClick={() => onClickDownload(record)}
                            disabled={!record.proforma?.url}
                            ghost
                        />
                    </Space>
                ),
            },
        ] as Array<ColumnProps<Penalty>>
    ).filter(Boolean);
    const onTableChange: TableProps<Penalty>['onChange'] = (pagination, filters, sorter) => {
        const sortObj = Array.isArray(sorter) ? sorter?.[0] : sorter;

        setQueryParams({
            page: (pagination.current ?? 1) - 1,
            sort: sortObj.column?.dataIndex ?? sortObj.column?.key ?? undefined,
            sortOrder: sortObj.order ? sortOrderConverter(sortObj.order) : undefined,
        });
    };
    const onFiltersValueChange: FormProps<FiltersFormValue>['onValuesChange'] = (changedValues, allValues) => {
        const { date, organization, status, type, statusFilter } = allValues;

        setQueryParams({
            date: date ? [date[0]?.toISOString(), date[1]?.toISOString()] : undefined,
            organization: organization ?? undefined,
            status: status ?? undefined,
            type: type ?? undefined,
            statusFilter: statusFilter || undefined,
            page: undefined,
        });
    };
    const onFiltersReset: FormProps<FiltersFormValue>['onReset'] = () => {
        const values = {
            date: undefined,
            organization: undefined,
            status: undefined,
            type: undefined,
            page: undefined,
            statusFilter: 'unfinished',
        };
        form.setFieldsValue(values);
        setQueryParams(values);
    };
    const onSearch = (value: string) => {
        if (searchTimeout) {
            window.clearTimeout(searchTimeout);
        }
        searchTimeout = window.setTimeout(() => {
            setQueryParams({
                search: value?.length ? value : undefined,
                page: undefined,
            });
        }, 300);
    };
    let heightMargin = 378;

    if ((listData?.pageCount ?? 0) > 1) {
        heightMargin += 48;
    }

    useEffect(() => {
        if (isDetails) {
            show(detailsDrawerId);
        } else {
            hide(detailsDrawerId);
        }
    }, [isDetails, penaltyId]); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <>
            <Seo title="Service Pénalités" />
            <Card>
                <Row
                    className="mb-16"
                    gutter={[
                        { xs: 16, lg: 24 },
                        { xs: 16, lg: 24 },
                    ]}
                    align="middle"
                >
                    <Col xs={24} sm={12} lg={14}>
                        <TitleSkeleton loading={isLoading}>
                            <Typography.Title className="mb-0">
                                {`${formatNumber(listData?.totalCount)} avis de pénalité${
                                    (listData?.totalCount ?? 0) > 1 ? 's' : ''
                                } reçu${(listData?.totalCount ?? 0) > 1 ? 's' : ''}`}
                            </Typography.Title>
                        </TitleSkeleton>
                    </Col>
                    <Col xs={24} sm={12} lg={10}>
                        <Input
                            placeholder="Rechercher un avis"
                            onChange={(e) => onSearch(e.target.value)}
                            defaultValue={search}
                            suffix={<SearchOutlined className="text-primary font-20" />}
                            size="small"
                            allowClear
                        />
                    </Col>
                </Row>
                <Form<FiltersFormValue>
                    onValuesChange={onFiltersValueChange}
                    onReset={onFiltersReset}
                    form={form}
                    initialValues={{
                        status,
                        type,
                        organization,
                        date: date?.length ? [dayjs(date[0]), dayjs(date[1])] : undefined,
                        statusFilter,
                    }}
                >
                    <Row
                        className="mb-16"
                        gutter={[
                            { xs: 16, lg: 24 },
                            { xs: 16, lg: 24 },
                        ]}
                        align="middle"
                        wrap
                    >
                        <Col>
                            <Form.Item name="statusFilter" noStyle>
                                <Radio.Group
                                    options={[
                                        {
                                            label: 'Non clôturés',
                                            value: 'unfinished',
                                        },
                                        {
                                            label: 'En attente fournisseur',
                                            value: 'waitingForProvider',
                                        },
                                        {
                                            label: 'Tous les avis',
                                            value: 'all',
                                        },
                                    ]}
                                    optionType="button"
                                    buttonStyle="solid"
                                    size="small"
                                />
                            </Form.Item>
                        </Col>
                        <Col flex="auto">
                            <Row gutter={[8, 8]} align="middle" justify="end" wrap>
                                {hasFilters && (
                                    <Col>
                                        <Button type="link" htmlType="reset" size="small" icon={<Close />}>
                                            Effacer les filtres
                                        </Button>
                                    </Col>
                                )}
                                <Col>
                                    <Form.Item name="date" noStyle>
                                        <DatePicker.RangePicker
                                            style={{ width: 320 }}
                                            size="small"
                                            format="DD/MM/YYYY"
                                            suffixIcon={<CalendarThree />}
                                            separator={<ArrowRight />}
                                            ranges={{
                                                'Année en cours': [dayjs().startOf('year'), dayjs()],
                                                '4 dernières semaines': [dayjs().subtract(4, 'weeks'), dayjs()],
                                            }}
                                            allowClear
                                        />
                                    </Form.Item>
                                </Col>
                                {hasMultiOrg && (
                                    <Col>
                                        <ProvidersField
                                            name="organization"
                                            selectProps={{
                                                placeholder: 'Tous les fournisseurs',
                                                style: { width: 220 },
                                                size: 'small',
                                                allowClear: true,
                                                dropdownMatchSelectWidth: false,
                                            }}
                                            noStyle
                                        />
                                    </Col>
                                )}
                                <Col>
                                    <Form.Item name="status" noStyle>
                                        <PenaltyStatusTagSelect
                                            style={{ width: 200 }}
                                            placeholder="Tous les statuts"
                                            size="small"
                                            dropdownMatchSelectWidth={false}
                                            disabled={statusFilter !== 'all'}
                                            allowClear
                                        />
                                    </Form.Item>
                                </Col>
                                <Col>
                                    <Form.Item name="type" noStyle>
                                        <PenaltyTypeTagSelect
                                            style={{ width: 200 }}
                                            placeholder="Tous les types"
                                            size="small"
                                            dropdownMatchSelectWidth={false}
                                            allowClear
                                        />
                                    </Form.Item>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Form>
                {isError ? (
                    <ApiResult status={error?.response?.status} />
                ) : (
                    <Table<Penalty>
                        rowKey="id"
                        columns={columns}
                        loading={isLoading || isFetching}
                        dataSource={listData?.items}
                        pagination={{
                            total: listData?.totalCount,
                            current: page + 1,
                            pageSize: listData?.pageSize,
                            position: ['bottomCenter'],
                            showSizeChanger: false,
                            hideOnSinglePage: true,
                        }}
                        locale={{
                            emptyText: (
                                <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="Aucuns avis de pénalités" />
                            ),
                        }}
                        rowClassName={(record) =>
                            record.status === PenaltyStatus.pending ? 'table-row-highlighted' : ''
                        }
                        onChange={onTableChange}
                        scroll={{ x: 1440, y: `calc(100vh - ${heightMargin}px)` }}
                    />
                )}
            </Card>
            <PenaltyDetailsDrawer id={detailsDrawerId} />
        </>
    );
};

export default PenaltiesList;
