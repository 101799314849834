import { selectUnit } from '@formatjs/intl-utils';

import {
    AppointmentReportStatus,
    AppointmentStatus,
    FeeStatus,
    Penalty,
    PenaltyContestationStatus,
    PenaltyInvoiceStatus,
    PenaltyStatus,
    RoleSlug,
} from '../queries/api/types';

export const defaultErrorMessage =
    'Nous sommes désolés, une erreur est survenue. Veuillez réessayer plus tard ou contacter un administrateur';

export const requiredFieldsText = '* Champs obligatoires';
export const requiredFieldText = '* Champ obligatoire';

export const formatNumber = (value?: number, options?: Intl.NumberFormatOptions) => {
    if (value === undefined) {
        return 0;
    }

    return Intl.NumberFormat('fr-FR', options).format(value);
};

export const formatDate = (value?: string | Date | null, options?: Intl.DateTimeFormatOptions) => {
    if (value === undefined || value === null) {
        return '—';
    }

    const date = value instanceof Date ? value : new Date(value);

    return Intl.DateTimeFormat(
        'fr-FR',
        options ?? {
            day: 'numeric',
            month: 'numeric',
            year: 'numeric',
        }
    ).format(date);
};

export const formatRelativeTime = (val?: string | Date | null, options?: Intl.RelativeTimeFormatOptions) => {
    if (val === undefined || val === null) {
        return '—';
    }

    const date = val instanceof Date ? val : new Date(val);
    const { value, unit } = selectUnit(Date.now() - (Date.now() - date.getTime()));

    return new Intl.RelativeTimeFormat(
        'fr-FR',
        options ?? {
            numeric: 'auto',
            style: 'short',
        }
    ).format(value, unit);
};

export const formatPrice = (amount: number | undefined, options?: Intl.NumberFormatOptions) => {
    if (amount === undefined) {
        return '—';
    } else {
        return Intl.NumberFormat('fr-FR', { style: 'currency', currency: 'EUR', ...options }).format(amount);
    }
};

export const translateRoleSlug = (slug?: RoleSlug) => {
    switch (slug) {
        case RoleSlug.superAdmin:
            return 'Super admin';
        case RoleSlug.owner:
            return 'Propriétaire';
        case RoleSlug.admin:
        case RoleSlug.providerAdmin:
            return 'Administrateur';
        case RoleSlug.user:
        case RoleSlug.providerUser:
            return 'Utilisateur';

        default:
            return slug ?? '—';
    }
};

// common translations
export const translatePenaltyStatus = (status?: PenaltyStatus) => {
    switch (status) {
        case PenaltyStatus.accepted:
            return 'Accepté';
        case PenaltyStatus.contestationAccepted:
            return 'Accordé';
        case PenaltyStatus.pending:
            return 'Nouveau';
        case PenaltyStatus.received:
            return 'Reçu';
        case PenaltyStatus.notContested:
            return 'Sans contestation';
        case PenaltyStatus.waitingForValidation:
            return 'À statuer en AC';
        case PenaltyStatus.toCheckAfterMeeting:
            return 'À revérifier suite RDV';
        case PenaltyStatus.closedAfterMeeting:
            return 'Clôturé avec RDV';
        case PenaltyStatus.closedToReimburseAfterMeeting:
            return 'Clôturé à annuler suite RDV';
        case PenaltyStatus.closedReimbursed:
            return 'Clôturé remboursé suite RDV';

        default:
            return status ?? '—';
    }
};

export const translatePenaltyListStatus = (penalty?: Penalty) => {
    if (penalty?.contestationStatus === PenaltyContestationStatus.waitingForProvider) {
        return translatePenaltyContestationStatus(penalty);
    }

    switch (penalty?.status) {
        case PenaltyStatus.contested:
            return `Contesté - ${penalty?.reviewing ? 'en traitement' : 'à traiter'}`;
        case PenaltyStatus.contestedPartially:
            return `Contesté part. - ${penalty?.reviewing ? 'en traitement' : 'à traiter'}`;

        default:
            return translatePenaltyStatus(penalty?.status);
    }
};

export const translatePenaltySelectStatus = (status?: PenaltyStatus) => {
    switch (status) {
        case PenaltyStatus.contested:
            return 'Contesté';
        case PenaltyStatus.contestedPartially:
            return 'Contesté partiellement';

        default:
            return translatePenaltyStatus(status);
    }
};

export const translatePenaltyDetailsStatus = (penalty?: Penalty) => {
    switch (penalty?.status) {
        case PenaltyStatus.contested:
            return 'Contesté';
        case PenaltyStatus.contestedPartially:
            return 'Contesté partiellement';

        default:
            return translatePenaltyStatus(penalty?.status);
    }
};

export const translatePenaltyContestationStatus = (penalty?: Penalty) => {
    switch (penalty?.contestationStatus) {
        case PenaltyContestationStatus.contestationAccepted:
            return 'Contestation accordée';
        case PenaltyContestationStatus.contested:
            return `Contesté - ${penalty?.reviewing ? 'en traitement' : 'à traiter'}`;
        case PenaltyContestationStatus.contestedPartially:
            return `Contesté part. - ${penalty?.reviewing ? 'en traitement' : 'à traiter'}`;
        case PenaltyContestationStatus.contestationPartiallyAccepted:
            return 'Contestation accordée partiellement';
        case PenaltyContestationStatus.contestationRejected:
            return 'Contestation refusée';
        case PenaltyContestationStatus.waitingForProvider:
            return 'En attente fournisseur';

        default:
            return undefined;
    }
};

export const translatePenaltyInvoiceStatus = (status?: PenaltyInvoiceStatus) => {
    switch (status) {
        case PenaltyInvoiceStatus.pending:
            return 'En attente';
        case PenaltyInvoiceStatus.toInvoice:
            return 'À facturer';
        case PenaltyInvoiceStatus.toInvoiceWithStoreCredit:
            return 'À facturer avec avoir';
        case PenaltyInvoiceStatus.toReimburse:
            return 'À rembourser';

        default:
            return status ?? '—';
    }
};

export const translatePenaltyType = (penalty?: Penalty) =>
    penalty?.computedProperties?.typeName ?? penalty?.type ?? '-';

export const translateFeeStatus = (status?: FeeStatus) => {
    switch (status) {
        case FeeStatus.contestationAccepted:
            return 'Accordée';
        case FeeStatus.contestationRejected:
            return 'Refusée';

        default:
            return '—';
    }
};

export const translateChangeAmountFeeStatus = (status?: FeeStatus) => {
    switch (status) {
        case FeeStatus.waitingForProvider:
        case FeeStatus.pending:
            return 'En attente';
        case FeeStatus.accepted:
            return 'Accepté';
        default:
            return 'Contesté';
    }
};

export const translateAppointmentStatus = (status?: AppointmentStatus, reportStatus?: AppointmentReportStatus) => {
    switch (status) {
        case AppointmentStatus.pending:
            return 'En attente';
        case AppointmentStatus.accepted:
        case AppointmentStatus.inProgress:
            return 'Prévu';
        case AppointmentStatus.done:
        case AppointmentStatus.notSigned:
            if (
                reportStatus &&
                [AppointmentReportStatus.pending, AppointmentReportStatus.inProgress].includes(reportStatus)
            ) {
                return 'Effectué, Attente CR';
            } else if (reportStatus === AppointmentReportStatus.waitingForValidation) {
                return 'Attente validation CR';
            } else if (reportStatus === AppointmentReportStatus.refused) {
                return 'CR non conforme';
            } else {
                return status ?? '—';
            }
        case AppointmentStatus.signed:
            return 'Clôturé';
        case AppointmentStatus.canceled:
            return 'Annulé';
        case AppointmentStatus.refused:
            return 'Refusé';

        default:
            return status ?? '—';
    }
};
